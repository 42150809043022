import classNames from 'classnames';
import '../../styles/quienesSomos.css';
import React, { useEffect } from 'react';
import circuitos from '../../assets/circuito.svg';
const QuienesSomos = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
     <div className = {classNames('div-quienes-somos',actualUrl)}>
        <div className = {classNames('quienes-somos',actualUrl)} >¿Quiénes somos?</div> 
        <div className = {classNames('div-quienes-somos-detalle',actualUrl)}> 
            <p className = {classNames('quienes-somos-detalle',actualUrl)} >Somos una empresa de tecnología que se erige como un bastión de innovación, donde técnicos de diversas procedencias e ideologías tienen la oportunidad de dar rienda suelta a su creatividad para desarrollar productos y servicios de alta calidad. Nuestro propósito es proporcionar un entorno seguro y acogedor donde los clientes puedan compartir sus necesidades tecnológicas con total confianza, y donde sus inquietudes sean atendidas con la mayor integridad y profesionalismo.</p>
        </div> 
        <div className = {classNames('div-circuitos-derecha',actualUrl)}>
            <img className = {classNames('circuitos-derecha',actualUrl)} src={circuitos} alt="Circuitos" />
     </div>
     </div>
    )
}
export default QuienesSomos;


