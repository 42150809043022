import React from 'react';
import HandTechno from '../comunes/HandTechno';
import InnovaTecno from '../comunes/InnovaTechno';
import Menu from '../comunes/Menu';
function Inicio() {
    return(
            <React.StrictMode>
                 <Menu/> 
                 <HandTechno/>
                 <InnovaTecno/>
            </React.StrictMode>
);
}
export default Inicio;