import React from 'react';
import MununoBlanco from '../comunes/MununoBlanco';
import Menu from '../comunes/Menu';
import RegresarInicio from '../comunes/RegresarInicio';
import QuienesSomos from '../comunes/QuienesSomos';
function Conocenos() {
    return(
            <React.StrictMode>
                 <MununoBlanco/>
                 <Menu/> 
                 <RegresarInicio/>
                 <QuienesSomos/>
            </React.StrictMode>
);
}
export default Conocenos;