import classNames from 'classnames';
import React, { useEffect } from 'react';
import '../../styles/mununoBlanco.css';
import logoMunBlanco from '../../assets/MUNUNO_2_letra-02.svg'
const MununoBlanco = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
     <div className = {classNames('div-logo-mununo-blanco',actualUrl)}>
            <img className = {classNames('logo-mununo-blanco',actualUrl)} src={logoMunBlanco} alt="MUNUNO" />
     </div>
    )
}
export default MununoBlanco;