import classNames from 'classnames';
import React, { useEffect } from 'react';
import '../../styles/regresarInicio.css';
import logoRegresarInicio from '../../assets/regresar_inicio.svg';
const RegresarInicio = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
     <div className = {classNames('div-regresar-inicio',actualUrl)}>
        <a href="/">
            <img className = {classNames('regresar-inicio',actualUrl)} src={logoRegresarInicio} alt="Inicio" />
        </a>    
     </div>
    )
}
export default RegresarInicio;