import classNames from 'classnames';
import '../../styles/leermas.css';
const LeerMas = () => { 
    const actualUrl = window.location.pathname.split('/').pop();
    return(  
       <div className={classNames('div-leer-mas',actualUrl)}>
            <button className = {classNames('leer-mas',actualUrl)} >Leer Más</button>
       </div>
   )
   }
   export default LeerMas;
