import classNames from 'classnames';
import React, { useState } from 'react';
import '../../styles/menu.css';
import hamburguesa from '../../assets/menu-hamburguesa-1.svg'
import { Link } from 'react-router-dom';
const Menu = () => {    
    const actualUrl = window.location.pathname.split('/').pop();
    const [ menuOpc , setMenu ] = useState( false )
    const toggleMenu = () => {
        setMenu(!menuOpc)
    }
    return(
        <header className={classNames('menu-div',actualUrl)}>
            <button 
                onClick={ toggleMenu }
                className={classNames('menu-button',actualUrl)}>
                <img className = {classNames('menu-hamburguesa',actualUrl)} src={hamburguesa} alt="menu-hamburguesa" />
            </button>
            <nav className={`${classNames('menu-nav',actualUrl)} ${ menuOpc ? 'isActive' : ''}`}>
                <ul className={classNames('menu-ul',actualUrl)}>
                    <li className={classNames('menu-li',actualUrl)}><a href="/" className={classNames('menu-a','inicio',actualUrl)}>Inicio</a></li>
                    <li className={classNames('menu-li',actualUrl)}><a href="/conocenos" className="menu-a">Conócenos</a></li>
                    <li className={classNames('menu-li',actualUrl)}><a href="/" className="menu-a">Proyectos</a></li>
                    <li className={classNames('menu-li',actualUrl)}><a href="/" className="menu-a">Soluciones</a></li>
                    <li className={classNames('menu-li',actualUrl)}><a href="/" className="menu-a">Contacto</a></li>
                </ul>
            </nav>
        </header>
    )   
}
export default Menu;

