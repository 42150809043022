import React from 'react';
import './styles/index.css';
import './styles/base.css';
import './styles/fonts.css';
import Base from './components/comunes/Base.jsx';
import MununoAzul from './components/comunes/MununoAzul.jsx';
import SearchBar from './components/comunes/SearchBar.jsx';
import Inicio from './components/paginas/Inicio.jsx';
import Conocenos from './components/paginas/Conocenos.jsx';
import LeerMas from './components/comunes/LeerMas.jsx';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
function Principal() {
    return(
        <BrowserRouter>
            <React.StrictMode>
                <Base/> 
                <MununoAzul/>
                <SearchBar/>
                <Routes>
                    <Route path="/" element={<Inicio/>}/>
                    <Route path="/conocenos" element={<Conocenos/>}/>
                </Routes>
                <LeerMas/>
            </React.StrictMode>
        </BrowserRouter>
);
}
export default Principal;