import classNames from 'classnames';
import '../../styles/mununoAzul.css';
import logoMununo from '../../assets/MUNUNO_LOGO-01.svg';
const MununoAzul = () =>  {
    const actualUrl = window.location.pathname.split('/').pop();
      return (
     <div className = {classNames('div-logo-mununo',actualUrl)}>
            <img className = {classNames('logo-mununo',actualUrl)} src={logoMununo} alt="MUNUNO" />
     </div>
    )
}
export default MununoAzul;