import classNames from 'classnames';
import '../../styles/searchbar.css';
import lupa from '../../assets/lupa.svg'
const SearchBar  = ({query, setQuery}) => {
  const actualUrl = window.location.pathname.split('/').pop();
    return (
      <div className={classNames('search-bar-div',actualUrl)}>
        <img className = {classNames('search-bar-lupa',actualUrl)} src={lupa} alt="looking-glass"  />
        <input className = {classNames('search-bar-input',actualUrl)}
          type="text"
          value={query}
        />
      </div>
    );
  }
export default SearchBar





