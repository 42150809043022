
import '../../styles/innovaTechno.css';
const InnovaTechno = () => {   
    return(  
        <div className = 'div-innova-techno'>
            <p className = 'innova-techno'>"Innovación tecnológica </p> 
                <p className = "con">con&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ."
                </p>
            <div className = 'alma'>alma humana</div>
        </div>
   )
   }
   export default InnovaTechno;